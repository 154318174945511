export type MotorId = string;

export type Motor = {
  _id: MotorId;
  motorId: string;
  manufacturer: string;
  modelNumber: string;
  rotorOverallLength: number;
  rotorHeadLength: number;
  rotorHeadDiameter: number;
  eccentricity: number;
  rotorWeight: number;
  statorLength: number;
  statorTubeOD: number;
  statorTubeID: number;
  statorWeight: number;
  speedMin: number;
  speedMax: number;
  torqueSlope: number;
  rpg: number;
  maxPressure: number;
  maxTorque: number;
  offBottomPressure: number;
  stallTorque: number;
  stallPressure: number;
  motorYield: number;
  hydraulicPerformanceCurve: HydraulicPerformanceCurve[];
  rpm: number[][];
  diffPressure: number[][];
};

export type HydraulicPerformanceCurve = {
  flowRate: number;
  pressureDrop: number;
};

export enum MotorMutation {
  SET_MOTORS = 'setMotors',
  DELETE_MOTOR = 'deleteMotor',
}

export enum MotorAction {
  FETCH_MOTORS = 'fetchMotors',
  DELETE_MOTOR = 'deleteMotorById',
}
